// src/hooks/usePromotionTracker.ts
import { useCallback } from "react";

export interface PromotionData {
    creativeName: string;
    creativeSlot?: string;
    promotionId: string;
    promotionName: string;
}

export interface PromotionEcommerceData {
    creative_name: string;
    creative_slot: string;
    promotion_id: string;
    promotion_name: string;
}

export const usePromotionTracker = () => {
    const trackPromotionClick = useCallback((data: PromotionData) => {
        // Ensure that window.dataLayer exists.
        window.dataLayer = window.dataLayer || [];
        // Clear any previous ecommerce object.
        window.dataLayer.push({ ecommerce: null });
        // Create your promotion ecommerce data object.
        const promotionEcommerceData: PromotionEcommerceData = {
            creative_name: data.creativeName,
            creative_slot: data.creativeSlot || "",
            promotion_id: data.promotionId,
            promotion_name: data.promotionName,
        };

        // Push the promotion tracking data.
        window.dataLayer.push({
            event: "select_promotion",
            ecommerce: promotionEcommerceData,
        });
    }, []);

    return trackPromotionClick;
};
