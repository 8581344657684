import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import Vimeo from "@u-wave/react-vimeo";
import YouTube from "react-youtube";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import {
    DA_Title,
    DA_TitleTag,
} from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl.helper";
import { getYouTubeId, getVimeoId } from "@baggie/core";
import { ImageViewer } from "@danishagro/cms/src/components/ImageViewer/ImageViewer.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useMarketingConsent } from "./hooks/useMarketingConsent.hook";
import { VideoPlayerViewerProps } from "./VideoPlayerViewer.interface";
import S from "./VideoPlayerViewer.module.scss";

interface YouTubeReadyEvent {
    target: {
        playVideo: () => void;
    };
}

const VideoPlayerViewer = (props: VideoPlayerViewerProps) => {
    const { getDictionaryString } = useTranslations();
    const { marketingConsent } = useMarketingConsent();

    const [autoplayWithIntro, setAutoplayWithIntro] = useState(props.video?.autoplay || false);

    const [videoInnerRef, videoInnerDimensions] = useMeasure();
    const [consentWrapperRef] = useMeasure();

    const [viewState, setViewState] = useState<string>(props.posterStart ? "INTRO" : "VIDEO");

    const autoplay = props.video?.autoplay || false;
    const loop = props.video?.autoplay || false;
    const muted = props.video?.autoplay || false;
    const hasControl = !props.video?.autoplay;
    const hasControlOption = props.video.controls ?? true;

    const [isSmall, setIsSmall] = useState(false);

    function useMeasure(): [
        MutableRefObject<HTMLDivElement | null>,
        { width: number; height: number }
    ] {
        const ref = useRef<HTMLDivElement | null>(null);
        const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

        useEffect(() => {
            if (ref.current) {
                const { width, height } = ref.current.getBoundingClientRect();
                setDimensions({ width, height });
            }
        }, []);

        return [ref, dimensions];
    }

    useEffect(() => {
        const updateSize = () => {
            const videoInnerHeight = videoInnerDimensions.height;
            if (videoInnerHeight < 290 && !isSmall) {
                setIsSmall(true);
            } else if (videoInnerHeight >= 290 && isSmall) {
                setIsSmall(false);
            }
        };
        updateSize();
    }, [videoInnerDimensions, isSmall]);

    const onVideoEnded = () => {
        if (props.posterEnd) {
            setViewState("OUTRO");
        }
    };

    const onVideoReady = (event: YouTubeReadyEvent) => {
        if (props.video?.autoplay && props.video.alias === "youtube") {
            setTimeout(() => {
                event.target.playVideo();
            }, 10);
        }
    };

    const onPosterClicked = () => {
        setViewState("VIDEO");
        setAutoplayWithIntro(true);
    };

    const handleRenew = () => {
        if (window.CookieConsent && typeof window.CookieConsent.renew === "function") {
            window.CookieConsent.renew();
        }
    };

    return (
        <>
            {/* Intro */}
            {viewState === "INTRO" && (
                <div className={S.introWrapper}>
                    <div
                        className={cn(S.videoInner, props.className)}
                        onClick={onPosterClicked}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                                onPosterClicked();
                            }
                        }}
                        role="button"
                        tabIndex={0}
                    >
                        <ImageViewer
                            src={props.posterStart?.url}
                            aspectRatioX={16}
                            aspectRatioY={9}
                            className={S.image}
                        />
                    </div>

                    <div className={S.playIconContainer}>
                        <DA_Icon name={DA_IconNames.Play} className={S.iconSize} />
                    </div>
                </div>
            )}

            {/* Video: YouTube */}
            {viewState === "VIDEO" &&
                props.video?.alias === "youtube" &&
                props.video.url &&
                // Allow video if marketingConsent is true OR if we're on localhost
                (marketingConsent === true ||
                    location.hostname === "localhost" ||
                    location.hostname.includes("azurewebsites.net")) && (
                    <div className={cn(S.videoInner, props.className)}>
                        <YouTube
                            videoId={getYouTubeId(props.video.url)}
                            opts={{
                                host: "https://www.youtube-nocookie.com",
                                playerVars: {
                                    modestbranding: 1,
                                    autoplay: props.video?.autoplay ? 1 : 0,
                                    controls: props.video?.autoplay ? 0 : 1,
                                },
                            }}
                            onReady={onVideoReady}
                            onEnd={onVideoEnded}
                        />
                    </div>
                )}

            {/* Video: Digizuite */}
            {viewState === "VIDEO" && props.video?.alias === "digizuite" && props.video.url && (
                <div className={cn(S.videoInner, props.className)}>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video
                        src={getAbsoluteUrl(props.video.url, "UMBRACO")}
                        controls={hasControlOption}
                        muted={muted}
                        autoPlay={autoplay}
                        loop={loop}
                        onCanPlay={(e) => onVideoReady(e as unknown as YouTubeReadyEvent)}
                        onEnded={onVideoEnded}
                        className={S.video}
                        playsInline
                    >
                        {/* Add a caption track if available */}
                    </video>
                </div>
            )}

            {/** Video: Vimeo */}
            {viewState === "VIDEO" &&
            marketingConsent &&
            props.video?.alias === "vimeo" &&
            props.video.url ? (
                <div className={cn(S.videoInner, props.className)}>
                    <Vimeo
                        video={getVimeoId(props.video.url)}
                        responsive={true}
                        onEnd={onVideoEnded}
                        autoplay={autoplayWithIntro}
                        controls={hasControl}
                        className={S.video}
                        muted={muted}
                        loop={loop}
                        onReady={onVideoReady}
                    />
                </div>
            ) : null}

            {/* Video: Local */}
            {viewState === "VIDEO" && props.video?.alias === "local" && props.video.url && (
                <div className={cn(S.videoInner, props.className)}>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video
                        src={getAbsoluteUrl(props.video.url, "UMBRACO")}
                        controls={hasControlOption}
                        muted={muted}
                        autoPlay={autoplay}
                        loop={loop}
                        onCanPlay={(e) => onVideoReady(e as unknown as YouTubeReadyEvent)}
                        onEnded={onVideoEnded}
                        className={S.video}
                        playsInline
                    >
                        {/* Add a caption track if available */}
                    </video>
                </div>
            )}

            {/* Outro */}
            {viewState === "OUTRO" && (
                <>
                    <div
                        className={cn(S.videoInner, props.className)}
                        onClick={onPosterClicked}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                                onPosterClicked();
                            }
                        }}
                        role="button"
                        tabIndex={0}
                    >
                        <ImageViewer
                            src={props.posterEnd?.url}
                            aspectRatioX={16}
                            aspectRatioY={9}
                            className={S.image}
                        />
                    </div>

                    <div className={S.playIconContainer}>
                        <DA_Icon name={DA_IconNames.Play} className={S.iconSize} />
                    </div>
                </>
            )}

            {/* Caption */}
            {props.video.caption && <div className={S.caption}>{props.video.caption}</div>}

            {/* No cookie consent video placeholder */}
            {(props.video?.alias === "youtube" || props.video?.alias === "vimeo") &&
                typeof window !== "undefined" &&
                // Remove the condition `location.hostname !== "localhost"` so that localhost shows videos even without consent if you want
                !marketingConsent &&
                !location.hostname.includes("azurewebsites.net") &&
                location.hostname !== "localhost" &&
                window.CookieConsent && (
                    <div
                        ref={videoInnerRef}
                        className={S.consentPlaceholderWrapper}
                        data-category="cookie_cat_marketing"
                    >
                        <div
                            ref={consentWrapperRef}
                            className={cn(S.consentPlaceholderBox, isSmall && S.smallContent)}
                        >
                            <DA_Icon name={DA_IconNames.Cookie} className={S.iconSize} />
                            <DA_Title h2 tag={DA_TitleTag.H2}>
                                {getDictionaryString("ConsentVideoPlaceholder.Headline")}
                            </DA_Title>
                            <div className={S.consentPlaceholderText}>
                                <DA_Text
                                    html={getDictionaryString("ConsentVideoPlaceholder.Text")}
                                />
                            </div>

                            <DA_Button
                                title={getDictionaryString("ConsentVideoPlaceholder.Button")}
                                onClick={handleRenew}
                            />
                        </div>
                    </div>
                )}
        </>
    );
};

export default VideoPlayerViewer;
