import { NewsPageProps } from "../NewsPage.interface";
/* eslint-disable @typescript-eslint/no-explicit-any */
export const generateNewsSchemaMarkup = (props: NewsPageProps) => {
    if (!props?.props) return null;

    const logo =
        globalThis.pageContent?.props?.schemaType?.[0]?.content?.props?.logo?.props?.umbracoFile !==
            undefined &&
        globalThis.pageContent?.props?.schemaType?.[0]?.content?.props?.logo?.props?.umbracoFile !==
            null
            ? globalThis.pageContent.props.schemaType[0].content.props.logo.props.umbracoFile
            : "";

    const { publishedDate, tags, title, modules, authorName, authorImage, metaTitle } = props.props;

    //ArticleBody
    const articleBody = modules
        ?.filter((module: any) => ["blockText", "blockQuote"].includes(module?.content?.alias))
        .map((module: any) =>
            module?.content?.alias === "blockText"
                ? module?.content?.props?.text
                : module?.content?.props?.quote
        )
        .join(" ");

    // Extracting images from blockImage and TextImage
    //Images aeeay
    const images = modules
        ?.filter((module: any) =>
            ["blockImage", "TextImage", "blockQuote", "blockHero"].includes(module?.content?.alias)
        )
        .map((module: any) => module?.content?.props?.image?.url)
        .filter(Boolean); // Ensure only valid URLs are included

    const schema = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        url: window.location.href,
        publisher: {
            "@type": "Organization",
            name: title,
            logo: logo,
        },
        headline: metaTitle || title,
        mainEntityOfPage: window.location.href,
        articleBody: articleBody,
        author: {
            "@type": "Person",
            name: authorName,
            image: authorImage?.url,
        },
        image: images,
        datePublished: publishedDate,
        keywords: tags?.join(", "), // Assumes tags are an array
    };

    return JSON.stringify(schema, null, 2);
};
