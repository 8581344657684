import { FilterBuilder, StringCollectionDataValue } from "@relewise/client";

const ASSORTMENT_NAME = "webUserGroups";

export function addWebUserGroupsFilter(
    f: FilterBuilder,
    customerNumber: string,
    cvrAndCustomerNumbers
) {
    // Initialize an empty array for group names.
    let groupNames: string[] = [];

    // If we have customer data (i.e. the user is logged in)
    if (cvrAndCustomerNumbers?.accounts?.length && customerNumber) {
        for (const account of cvrAndCustomerNumbers.accounts) {
            const customer = account.customers.find((c) => c.number === customerNumber);
            if (customer) {
                // Get the customer groups from the matched customer, if available.
                groupNames = (customer.customerGroups || []).map((group) => group.name);
                break;
            }
        }
    }

    // Determine the default filter from globalThis
    const defaultFilter = globalThis.relewiseDefaultGroupFilter;

    // Always add the default group filter.
    groupNames.push(defaultFilter);

    // Remove any duplicates (if needed)
    groupNames = Array.from(new Set(groupNames));

    const groups = new StringCollectionDataValue(groupNames);

    f.addProductDataFilter(
        ASSORTMENT_NAME,
        (cb) => {
            cb.addContainsCondition(groups, "Any");
        },
        true, // mustMatchAllConditions
        true, // filterOutIfKeyIsNotFound
        false // negated
    );
}
