// src/helpers/adjustUrl.helper.ts
const getRelativeUrlPart = (url: string, urlOrigin: string) => {
    const isDomainUrlMatch = url?.startsWith(urlOrigin);

    if (isDomainUrlMatch || window.location.hostname === "localhost") {
        try {
            const parsedUrl = new URL(url, urlOrigin);
            return parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
        } catch (error) {
            // If it's already a relative URL or invalid URL, return as is
            return url;
        }
    }

    return url;
};

export const adjustUrl = (url: string): string => {
    /*
    NOTE:
    --------------------------------------------------------------
    To avoid a large amount of http errors in 
    Google Search Console (due to relative urls in script area), 
    absolute navigation urls were introduced in this story: 
    https://picopublish.atlassian.net/browse/DASLA-37.

    So this helper function will return relative part of current domain urls.
    Other external links will not be adjusted

    This way we can keep the absolute urls in script, 
    but still navigate PLP/PDP links with route links instead of full page reloads.
    --------------------------------------------------------------
    */

    if (typeof window !== "undefined") {
        return getRelativeUrlPart(url, globalThis.b2bFrontendUrl);
    }

    return url;
};
