// GTM Tracking
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PAGE_VIEW: string = "page_view";

type DataLayerEventType = typeof PAGE_VIEW | string;

interface BasicDataLayerObject {
    event?: DataLayerEventType;
    originalLocation?: string;
}

interface ScrollDistanceDataLayerObject {
    event: string;
    scroll_distance: string;
}

interface PageTypeDataLayerObject {
    pageType: string;
}

interface GoogleAdsRemarketingDataLayerObject {
    event: string;
    ecomm_pagetype: string;
    ecomm_totalvalue: string | number;
    ecomm_prodid: string;
}

interface AddAsFavoritesObject {
    event: string;
    product_name: string;
}

interface EcommerceDataLayerObject {
    event?: string;
    ecommerce: {
        items?: Array<{
            item_id: string;
            item_name: string;
            // ... other properties you might need
        }>;
    };
}

type EventDataLayerObject = {
    event: DataLayerEventType;
};

interface PromotionDataLayerObject {
    event: "select_promotion";
    ecommerce: {
        creative_name: string;
        creative_slot: string;
        promotion_id: string;
        promotion_name: string;
    };
}

type DataLayerObject =
    | ScrollDistanceDataLayerObject
    | BasicDataLayerObject
    | PageTypeDataLayerObject
    | EcommerceDataLayerObject
    | EventDataLayerObject
    | GoogleAdsRemarketingDataLayerObject
    | AddAsFavoritesObject
    | PromotionDataLayerObject;

// Extend window object to recognize dataLayer for TypeScript
declare global {
    interface Window {
        dataLayer: DataLayerObject[];
    }
}

const MAX_DATA_LAYER_SIZE = 90;

export const pushToDataLayer = (data: DataLayerObject) => {
    if (window?.dataLayer?.length > MAX_DATA_LAYER_SIZE) {
        // trim old entries, keeping only the latest
        window.dataLayer?.splice(0, window.dataLayer?.length - MAX_DATA_LAYER_SIZE);
    }
    window.dataLayer?.push(data);
};

export const pushEventToDataLayer = (eventType: DataLayerEventType) => {
    pushToDataLayer({ event: eventType });
};

const useTrackPageView = (pageType?: string) => {
    const location = useLocation();

    useEffect(() => {
        // Push the initial page load to dataLayer
        pushToDataLayer({
            originalLocation:
                document.location.protocol +
                "//" +
                document.location.hostname +
                document.location.pathname +
                document.location.search,
        });

        // If pageType is provided, push it to dataLayer
        if (pageType) {
            pushToDataLayer({
                pageType: pageType,
            });
        }

        // The useLocation hook will cause re-renders on route changes, which will allow us to track page views.
        pushToDataLayer({
            event: PAGE_VIEW,
            originalLocation:
                document.location.protocol +
                "//" +
                document.location.hostname +
                location.pathname +
                location.search,
        });
    }, [location, pageType]);
};

export default useTrackPageView;
