/* eslint-disable @typescript-eslint/no-explicit-any */
export const generatePageSchema = (props: any, isFrontPage: boolean) => {
    const logo =
        globalThis.pageContent?.props?.schemaType?.[0]?.content?.props?.logo?.props?.umbracoFile !==
            undefined &&
        globalThis.pageContent?.props?.schemaType?.[0]?.content?.props?.logo?.props?.umbracoFile !==
            null
            ? globalThis.pageContent.props.schemaType[0].content.props.logo.props.umbracoFile
            : "";

    const footerObj = globalThis.pageContent?.props?.schemaType?.[0]?.content?.props || {};
    const _footerAddress = footerObj?.address?.[0]?.content?.props || {};
    const siteName = globalThis.pageContent?.siteName || "";
    const heroImage = globalThis.pageContent?.props?.hero?.[0]?.content?.props?.image?.url;
    const schemaTypeData = globalThis.pageContent?.props?.schemaType || [];

    // Extract VAT ID from the first schemaType if available
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const vatID =
        schemaTypeData.find((item: any) => item.content?.alias === "organization")?.content?.props
            ?.vatID || "";

    // Hvis det er forsiden, kun Organization
    if (isFrontPage) {
        const organizationSchema = {
            "@context": "https://schema.org",
            "@type": "Organization",
            name: siteName || props.props?.metaTitle,
            description: props.props?.metaDescription,
            url: window.location.href,
            logo: logo,
            image: heroImage,
            address: {
                "@type": "PostalAddress",
                streetAddress: _footerAddress?.street || "",
                addressLocality: _footerAddress?.locality || "",
                addressRegion: _footerAddress?.region || "",
                postalCode: _footerAddress?.postalCode || "",
                addressCountry: _footerAddress?.country || "",
            },
            additionalProperty: [
                {
                    "@type": "PropertyValue",
                    name: "VAT ID",
                    value: vatID || "",
                },
            ],
        };

        return JSON.stringify([organizationSchema]); // Returner kun Organization schema for forsiden
    }

    // Map departments only for non-front pages
    const departments = !isFrontPage
        ? schemaTypeData
              .filter((item: any) => item.content?.alias === "localBusiness")
              .flatMap((localBusiness: any) => {
                  const businessProps = localBusiness?.content?.props || {};
                  return businessProps?.deparment?.map((dept: any) => {
                      const deptProps = dept?.content?.props || {};
                      const openingHours = deptProps?.openingHoursSpecification?.map(
                          (hours: any) => {
                              const hoursProps = hours?.content?.props || {};
                              return {
                                  "@type": "OpeningHoursSpecification",
                                  dayOfWeek: hoursProps?.dayOfWeek || [], // Directly use dayOfWeek array
                                  opens: hoursProps?.opens || "",
                                  closes: hoursProps?.closes || "",
                              };
                          }
                      );

                      // Return the department data without calling ensureType
                      return {
                          "@type": "LocalBusiness",
                          name: deptProps?.name || "Default Department Name",
                          openingHoursSpecification: openingHours || [], // Opening hours if available
                      };
                  });
              })
              .filter(Boolean)
        : []; // Return empty array for front page

    // Map LocalBusiness schema for the page (with departments, if available)
    const schemas = schemaTypeData
        .map((item: any) => {
            const { alias, props } = item.content;

            if (alias === "localBusiness") {
                const address = props?.address?.[0]?.content?.props || {};

                return {
                    "@context": "https://schema.org",
                    "@type": "LocalBusiness",
                    name: props?.name || props?.siteName || "",
                    telephone: props?.telephone || "",
                    email: props?.email || "",
                    url: props?.url || window.location.href,
                    logo: logo,
                    image: heroImage,
                    address: {
                        "@type": "PostalAddress",
                        streetAddress: address?.street || "",
                        addressLocality: address?.locality || "",
                        addressRegion: address?.region || "",
                        postalCode: address?.postalCode || "",
                        addressCountry: address?.country || "",
                    },
                    sameAs: props?.sameAs || [], // Social media links or other relevant links
                    openingHoursSpecification:
                        props?.openingHoursSpecification?.map((hours: any) => {
                            const hoursProps = hours?.content?.props || {};
                            return {
                                "@type": "OpeningHoursSpecification",
                                dayOfWeek: hoursProps?.dayOfWeek || [],
                                opens: hoursProps?.opens,
                                closes: hoursProps?.closes,
                            };
                        }) || [], // Add opening hours if they exist
                    department: departments || [], // Include mapped departments
                };
            }

            return null; // Return null if it's not LocalBusiness
        })
        .filter(Boolean); // Filter out null values

    return JSON.stringify(schemas); // Returner LocalBusiness schema for de relevante sider
};
