import React, { useMemo } from "react";
import { Buttons } from "@danishagro/cms/src/components/Buttons/Buttons.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import {
    DA_Title,
    DA_TitleTag,
} from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { ImageViewer } from "@danishagro/cms/src/components/ImageViewer/ImageViewer.component";
import { useBackgroundColor } from "@danishagro/cms/src/blocks/hooks/useBackgroundColor/useBackgroundColor.hook";
import { usePadding } from "src/blocks/hooks/usePadding/usePadding.hook";
import VideoPlayerViewer from "src/components/VideoPlayerViewer/VideoPlayerViewer.component";
import { usePromotionTracker } from "@danishagro/shared/src/gtm/usePromotionTracker";
import { ButtonProps } from "src/blocks/interfaces/button.interface";
import { HeroProps } from "./Hero.interface";
import S from "./Hero.module.scss";

const Hero = (props: HeroProps) => {
    const { backgroundColorClass, alternativeButtonTheme, hasBackgroundColor } = useBackgroundColor(
        props.backgroundColor
    );
    const { paddingClass } = usePadding(props.isCompact, hasBackgroundColor);

    // GTM tracking
    const trackPromotionClick = usePromotionTracker();

    const handleCTAClick = (button: ButtonProps) => {
        trackPromotionClick({
            creativeName: props?.alias || "",
            promotionId: props?.headline || "",
            promotionName: button.label, // use the clicked button's label
        });
    };

    const layoutClass = useMemo(() => {
        // Image Cover w/black filter
        if (
            !props.position &&
            props.image?.url &&
            (props.filter === "FILTER_BLACK_BACKGROUND" || !props.filter)
        ) {
            return S.layoutCoverBlackFilter;
        }

        // Image Cover w/white filter
        else if (
            !props.position &&
            props.image?.url &&
            props.filter === "FILTER_WHITE_BACKGROUND"
        ) {
            return S.layoutCoverWhiteFilter;
        }

        // Video Cover w/black filter
        else if (
            !props.position &&
            props.video?.url &&
            (props.filter === "FILTER_BLACK_BACKGROUND" || !props.filter)
        ) {
            return S.layoutCoverBlackFilter;
        }

        // Video Cover w/white filter
        else if (
            !props.position &&
            props.video?.url &&
            props.filter === "FILTER_WHITE_BACKGROUND"
        ) {
            return S.layoutCoverWhiteFilter;
        }

        // Image Left
        else if (props.position === "POSITION_IMAGE_LEFT" && props.image?.url) {
            return S.heroImageLeft;
        }

        // Image Right
        else if (props.position === "POSITION_IMAGE_RIGHT" && props.image?.url) {
            return S.heroImageRight;
        }

        // VIDEO Left
        else if (props.position === "POSITION_IMAGE_LEFT" && props.video?.url) {
            return S.heroImageLeft;
        }

        // VIDEO Right
        else if (props.position === "POSITION_IMAGE_RIGHT" && props.video?.url) {
            return S.heroImageRight;
        }

        // No Image
        else {
            return S.heroText;
        }
    }, [props.filter, props.image?.url, props.position, props.video?.url]);

    // Mappers

    const heroAlternativeButtonTheme = useMemo(() => {
        // Filter: Black Background
        if (
            !props.position &&
            props.image?.url &&
            (props.filter === "FILTER_BLACK_BACKGROUND" || !props.filter)
        ) {
            return "PARTIAL_WHITE";
        }

        return alternativeButtonTheme;
    }, [props.filter, props.image?.url, props.position, alternativeButtonTheme]);

    //Just add the thinHero class if no image from Umbraco (image) or digizuiteImage
    const thinHeroClass = props?.thin === "THIN_HERO" && S.thinHero;

    return (
        <div
            className={cn(
                S.wrapper,
                props.className,
                layoutClass,
                paddingClass,
                backgroundColorClass
            )}
        >
            <DA_Container noVerticalPadding className={S.container}>
                <div className={cn(S.inner, thinHeroClass)}>
                    {/** Video */}
                    {props.video && props.video?.alias ? (
                        <div className={S.videoWrapper}>
                            <VideoPlayerViewer
                                className={S.video}
                                video={{
                                    alias: props.video?.alias,
                                    url: props.video?.url,
                                    autoplay: true,
                                    controls: false,
                                }}
                            />
                        </div>
                    ) : (
                        <div
                            className={cn(S.imageWrapper, !props.image?.url && S.imageWrapperFull)}
                        >
                            {/** Image  */}
                            {props.image?.url && (
                                <ImageViewer
                                    src={props.image?.url}
                                    alternative={props.alternative}
                                    aspectRatioX={props.image?.width}
                                    aspectRatioY={props.image?.height}
                                    className={S.image}
                                    lazy={false}
                                />
                            )}
                        </div>
                    )}

                    {/** Content */}
                    <div className={S.contentWrapper}>
                        <div className={S.content}>
                            {/* Logo */}
                            {props.logo ? (
                                <ImageViewer
                                    src={props.logo.url}
                                    aspectRatioX={props.logo.width}
                                    aspectRatioY={props.logo.height}
                                    className={S.logo}
                                    lazy={false}
                                />
                            ) : null}

                            {/** Tagline */}
                            {props.tagline ? (
                                <span className={S.tagline}>{props.tagline}</span>
                            ) : null}

                            {/** Title */}
                            {props.headline ? (
                                <DA_Title h1 tag={DA_TitleTag.H1} noMargin fullWidth>
                                    {props.headline}
                                </DA_Title>
                            ) : null}

                            {/** Text */}
                            {props.text ? <DA_Text html={props.text} noPadding /> : null}

                            {/** Buttons */}
                            <Buttons
                                buttons={props.buttons}
                                theme={heroAlternativeButtonTheme}
                                onClick={handleCTAClick}
                            />
                        </div>
                    </div>
                </div>
            </DA_Container>
        </div>
    );
};

export default Hero;
